import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select'
import ColoredBox from '../../content/ColoredBox'
import withTranslations from '../../hoc/withTranslations'
import ContentButton from '../ContentButton'
import Translation from '../../../types/Translation'
import { IQuoteRequestTemplate } from '../../../helper/QuoteRequestHelper'
import { LangKey } from '../../../types/Languages'
import GenericForm from './GenericForm'

import * as styles from './QuoteRequestForm.module.scss'

class QuoteRequestForm extends React.Component<QuoteRequestFormProps, QuoteRequestFormState> {
    constructor(props: QuoteRequestFormProps) {
        super(props)

        const product = this.props.templateData.product

        let selectedProduct = {}
        if (product) {
            selectedProduct = product
        }

        this.state = {
            displayOptional: false,
            form: {
                product: selectedProduct,
                firstname: '',
                lastname: '',
                email: '',
                comments: '',
            },
        }
    }

    toggleOptionalFields = () => {
        this.setState({ displayOptional: !this.state.displayOptional })
    }

    handleSubmit = () => {
        if (this.props.onSubmit) {
            const formData = this.state.form
            const sentData = this.prepareSentData(formData)
            this.props.onSubmit(formData, sentData)
        }
    }

    handleChange = (e: any) => {
        const field = e.target.name
        const newState = {
            form: {
                ...this.state.form,
                [field]: e.target.value,
            },
        }

        this.setState(newState)
    }

    handleProductChange = (opt: any) => {
        this.setState({
            form: {
                ...this.state.form,
                product: opt,
            },
        })
    }

    prepareSentData(formData: any) {
        const sentData: any = {}

        Object.keys(formData).forEach((key) => {
            const val = formData[key]

            let field

            switch (key) {
                case 'product':
                    field = this.props.t('form', 'general.field.product.label')
                    if (typeof val === 'object' && val !== null) {
                        sentData[field] = val.label
                    }
                    break
                case 'firstname':
                    field = this.props.t('form', 'general.field.firstname.label')
                    sentData[field] = val
                    break
                case 'lastname':
                    field = this.props.t('form', 'general.field.lastname.label')
                    sentData[field] = val
                    break
                case 'email':
                    field = this.props.t('form', 'general.field.email.label')
                    sentData[field] = val
                    break
                case 'comments':
                    field = this.props.t('form', 'general.field.comments.label')
                    sentData[field] = val
                    break

                default:
            }
        })

        return sentData
    }

    render() {
        const { t } = this.props

        const { product } = this.state.form
        const { productOptions, productPlatforms } = this.props.templateData
        const productEnabledOptions = productOptions.filter((p) => p.quoteRequestEnabled).reverse()
        let availableOperatingSystems = ''

        availableOperatingSystems = productPlatforms[product.value]

        return (
            <GenericForm onSubmit={this.handleSubmit}>
                <Row>
                    <Col sm={12}>
                        <h1>{t('form', 'form.quoterequest.title')}</h1>
                        <p>{t('form', 'form.quoterequest.text.fillout')}</p>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <ColoredBox pdftColor={'blue'} spaceBottom={true}>
                            <div className={styles.coloredBox}>
                                <Row>
                                    <Col>
                                        <h2>{t('form', 'general.field.product.label')}</h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>{t('form', 'general.field.product.label')}*</Form.Label>

                                            <Select
                                                defaultValue={product}
                                                onChange={this.handleProductChange}
                                                options={productEnabledOptions}
                                                classNamePrefix={'pdft_select'}
                                            />
                                        </Form.Group>
                                    </Col>
                                    {availableOperatingSystems && (
                                        <Col sm={6}>
                                            <Form.Group className={styles.pseudoHeading}>
                                                <div>
                                                    <div>
                                                        <span>
                                                            <b> {t('form', 'form.quote.os.available')}</b>
                                                        </span>
                                                    </div>
                                                    <div>{availableOperatingSystems}</div>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </ColoredBox>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <ColoredBox pdftColor={'blue'} spaceBottom={true}>
                            <div className={styles.coloredBox}>
                                <Row>
                                    <Col sm={12}>
                                        <h2>{t('form', 'general.contactDetails.title')}</h2>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={6}>
                                        <Form.Group controlId={'form.firstname'}>
                                            <Form.Label>
                                                {t('form', 'general.field.firstname.label')}
                                                <span>*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type={'text'}
                                                name={'firstname'}
                                                required={true}
                                                onChange={this.handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {t('form', 'form.validate.firstname.invalid')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group controlId={'form.lastname'}>
                                            <Form.Label>
                                                {t('form', 'general.field.lastname.label')}
                                                <span>*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type={'text'}
                                                name={'lastname'}
                                                required={true}
                                                onChange={this.handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {t('form', 'form.validate.lastname.invalid')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Group controlId={'form.email'}>
                                            <Form.Label>
                                                {t('form', 'general.field.email.label')}
                                                <span>*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="email"
                                                name={'email'}
                                                required={true}
                                                onChange={this.handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {t('form', 'form.validate.email.invalid')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        <Form.Group style={{ marginTop: '5px' }} controlId={'form.comments'}>
                                            <Form.Label>{t('form', 'general.field.comments.label')}</Form.Label>
                                            <div>
                                                <Form.Control
                                                    as="textarea"
                                                    rows="5"
                                                    name={'comments'}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <ContentButton type="submit" variant="primary">
                                                {t('form', 'general.btn.send')}
                                            </ContentButton>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </ColoredBox>
                    </Col>
                </Row>
            </GenericForm>
        )
    }
}

export default withTranslations(QuoteRequestForm)

interface QuoteRequestFormProps extends Translation {
    onSubmit: any
    language: LangKey
    templateData: IQuoteRequestTemplate
}

interface QuoteRequestFormState {
    displayOptional: boolean
    form: any
}

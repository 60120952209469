import axios from 'axios'
import * as React from 'react'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import QuoteRequestForm from '../components/content/form/QuoteRequestForm'
import withTranslations from '../components/hoc/withTranslations'
import postifyData from '../helper/FormHelper'
import { reportGtmEvent } from '../helper/TagManagerHelper'
import MainLayout from '../layouts/MainLayout'
import SuccessPage from '../templates/SuccessPageTemplate'
import PageContext from '../types/PageContext'
import Translation from '../types/Translation'

class QuoteRequestPageTemplate extends React.Component<Props, {}> {
    state = {
        submitSuccess: false,
        sentData: null,
        error: false,
        errorMsg: '',
    }

    constructor(props: Props) {
        super(props)

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    async handleSubmit(formData: any, sentData: any) {
        this.setState({
            error: false,
            errorMsg: '',
        })

        const url = '/api/v1.0/QuoteRequestForm'

        const postFormData = {
            ...postifyData(formData),
        }

        try {
            reportGtmEvent('Form', 'submit', { form: 'quoteRequest', label: `_${formData.product}` })
            await axios.post(url, postFormData, {
                headers: {
                    'Accept-Language': this.props.pageContext.language,
                },
            })

            this.setState({
                submitSuccess: true,
                sentData,
            })
        } catch (error: any) {
            console.log(error)

            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }

            this.displayFormError(this.props.t('form', 'contactForm.alert.rejected'))
        }
    }

    displayFormError(errorMsg: string) {
        this.setState({
            error: true,
            errorMsg,
        })
    }

    public render() {
        const { pageContext } = this.props
        const { templateData } = pageContext

        const { submitSuccess, sentData, error, errorMsg } = this.state

        return (
            <MainLayout pageContext={pageContext}>
                <Container>
                    <Row>
                        <Col sm={12}>
                            {error && <Alert variant={'danger'}>{errorMsg}</Alert>}
                            {!submitSuccess && (
                                <QuoteRequestForm
                                    onSubmit={this.handleSubmit}
                                    language={pageContext.language}
                                    templateData={templateData}
                                />
                            )}
                            {submitSuccess && <SuccessPage formData={sentData} pageContext={pageContext} />}
                        </Col>
                    </Row>
                </Container>
            </MainLayout>
        )
    }
}

export default withTranslations(QuoteRequestPageTemplate)

interface Props extends Translation {
    pageContext: PageContext<any>
}
